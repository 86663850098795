<template>
  <div>
    <div class="search-container">
      <div class="search-box">
        <i class="search" />
        <input v-model="keyword" type="text" placeholder="请输入手机号或扫描MEI码查询">
        <i class="scan" />
      </div>
      <div class="search-btn" @click="getData">搜索</div>
    </div>
    <div v-if="list && list.length" class="list-box">
      <div v-for="item in list" :key="item.order_id" class="item-box">
        <div class="head-box">
          <div class="order-num">
            <img src="../../assets/images/qianmi/icon_bdh.png" alt="" srcset="">
            保单号: {{item.order_sn}}
          </div>
          <div class="tag">权益单</div>
        </div>
        <div class="info-box">
          <div class="info-item">
            <label>设备IMEI：</label>{{item.imei}}
          </div>
          <div class="info-item">
            <label>绑定手机号：</label>{{ item.phone }}
          </div>
          <div class="info-item">
            <label>投保日期：</label>{{ item.add_date }}
          </div>
          <div class="info-item">
            <label>保险有效期：</label>{{ item.term_time_end }}
          </div>
          <div class="info-item">
            <label>保险总额度：</label>{{item.total_limit}}
          </div>
        </div>
        <div class="price-box">
          <div>已使用额度：<span>{{item.use_limit}}元</span></div>
          <div>剩余额度：<span class="red">{{item.residue_limit}}元</span></div>
        </div>
      </div>
    </div>
    <div v-else-if="list" class="no-data">
      <img src="../../assets/images/qianmi/img_wk.png" alt="" srcset="">
      抱歉！您的查询暂无内容
    </div>
  </div>
</template>
<script>
import { orderQuery } from "@/api/qianmi"
export default {
  data () {
    return {
      keyword: "",
      list: null
    }
  },
  created() {
    const { code } = this.$route.query
    this.keyword = code || ''
    this.getData()
  },
  methods: {
    getData() {
      orderQuery({ keyword: this.keyword }).then(res => {
        this.list = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search-container{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 21px 15px 30px;
  background: #FFF;
  position: sticky;
  top: 0;
}
.search-box{
  width: 100%;
  height: 72px;
  background: #F5F5F5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-right: 9px;
  padding: 0 19px;
  i {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    &.search{
      background-image: url('../../assets/images/qianmi/icon_ss.png');
      background-size: 100% 100%;
    }
    &.scan{
      background-image: url('../../assets/images/qianmi/icon_sm.png');
      background-size: 100% 100%;
    }
  }
  input {
    width: 100%;
    background: inherit;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    padding: 0 7px;
  }

}
.search-btn{
  width: 150px;
  height: 90px;
  background-image: url('../../assets/images/qianmi/btn_ssan.png');
  background-size: 100% 100%;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}


.list-box{
  padding: 30px;
  padding-top: 15px;
}
.item-box{
  background: #FFFFFF;
  box-shadow: 0px 4px 21px 9px rgba(196,197,197,0.4);
  border-radius: 20px;
  overflow: hidden;
  padding-bottom: 30px;
  margin-bottom: 26px;
  .head-box{
    height: 80px;
    border-bottom: 2px solid #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 32px;
    .order-num{
      display: flex;
      align-items: center;
      img{
        width: 26px;
        height: 30px;
        margin-right: 10px;
      }
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .tag{
      height: 100%;
      width: 148px;
      background-image: url(../../assets/images/qianmi/bg_qydd.png);
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 19px;

      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #F4A601;
    }
  }
  .info-box{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    padding-top: 30px;
    padding-left: 32px;
    label{
      color: #999999;
      width: 170px;
      display: inline-block;
    }
    .info-item{
      line-height: 1em;
      margin-bottom: 30px;
    }
  }
  .price-box{
    width: 650px;
    height: 80px;
    background: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: 20px;
    padding: 26px 12px;
    span{
      font-weight: 800;
      color: #F4A601;
      &.red{
        color: #F75846;
      }
    }
  }
}

.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 350px;

  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  img{
    width: 170.6px;
    height: 221.6px;
    margin-bottom: 27px;
  }
}
</style>